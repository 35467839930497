import type { ReactNode } from 'react';
import { ModalTrigger } from '@mentimeter/ragnar-ui/modal';
import { CaptureInteraction } from './CaptureInteraction';

export function PaywallModalTrigger({
  trigger,
  children,
}: {
  trigger: () => void;
  children: ReactNode;
}) {
  return (
    <ModalTrigger>
      <CaptureInteraction trigger={trigger}>{children}</CaptureInteraction>
    </ModalTrigger>
  );
}
