import { useToast } from '@mentimeter/toast';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Textarea } from '@mentimeter/ragnar-ui/input/textarea';
import {
  REQUEST_UPGRADE_DESCRIPTION,
  REQUEST_UPGRADE_TITLE,
} from '../constants';
import { PaywallModalTrigger } from '../../triggers/PaywallModalTrigger';

export const MAX_MESSAGE_LENGTH = 1000;

export function RequestUpgradeModalContent({
  title = REQUEST_UPGRADE_TITLE,
  description = REQUEST_UPGRADE_DESCRIPTION,
  open,
  withTrigger = true,
  onOpenChange,
  onRequestUpgrade,
  onTrigger,
  children,
}: {
  title?: string | undefined;
  description?: string | undefined;
  open: boolean;
  withTrigger?: boolean;
  onOpenChange: (open: boolean) => void;
  onRequestUpgrade: (message: string) => Promise<void>;
  onTrigger?: (() => void) | undefined;
  children?: ReactNode;
}) {
  const [message, setMessage] = useState('');
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  function handleTrigger() {
    onOpenChange(true);
    onTrigger?.();
  }

  return (
    <ModalRoot modal open={open} onOpenChange={onOpenChange}>
      {withTrigger && (
        <PaywallModalTrigger trigger={handleTrigger}>
          {children}
        </PaywallModalTrigger>
      )}

      {!withTrigger && children}

      <ModalContainer
        width="480px"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalHeader title={title}>{title}</ModalHeader>
        <ModalBody>
          <Text>{description}</Text>

          <Box width="100%" mt={3} alignItems="stretch">
            <Text color="text" mb={2}>
              Message (optional)
            </Text>
            <Textarea
              id="request-upgrade-message"
              data-testid="request-upgrade-message"
              value={message}
              placeholder="Write a message to your admin"
              onChange={handleTextInputChange}
              extend={({ theme }) => ({
                borderColor: theme.colors.border,
              })}
            />
            {error && (
              <Text mt={2} color="textNegative">
                {error}
              </Text>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <ModalDismiss>
            <Button>Cancel</Button>
          </ModalDismiss>
          <Button
            data-testid="request-upgrade-button"
            variant="positive"
            onClick={handleClickRequestUpgrade}
          >
            Request upgrade
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );

  function handleTextInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setError(null);
    setMessage(e.target.value || '');
  }

  async function handleClickRequestUpgrade() {
    if (message.length > MAX_MESSAGE_LENGTH) {
      setError(
        `Your message is too long. Please keep it under ${MAX_MESSAGE_LENGTH} characters.`,
      );
      return;
    }

    await onRequestUpgrade(message);
    showUpgradeRequestedToast();
    setMessage('');
    onOpenChange(false);
  }

  function showUpgradeRequestedToast() {
    toast.displayToast({
      description: 'Your upgrade request has been sent',
      autoDismiss: true,
    });
  }
}
