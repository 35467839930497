import * as React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import type { Extend } from '@mentimeter/ragnar-dsc';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Text } from '../text';
import { Box } from '../box';
import type { BoxT } from '../box';
import { Clickable } from '../clickable';
import { PopoverDismiss } from './PopoverDismiss';
import { variants } from './PopoverHeader.variants';

interface PopoverHeaderProps extends BoxT {
  showDismiss?: boolean;
  dismissLabel?: string;
  onDismissClick?: () => void;
  popoverDismissStyles?: Extend;
  children?: string;
}

export const PopoverHeader = ({
  showDismiss = true,
  dismissLabel,
  onDismissClick,
  popoverDismissStyles,
  children,
  className,
  ...rest
}: PopoverHeaderProps) => {
  const closeLabel = dismissLabel
    ? dismissLabel
    : `Close ${children ? children + ' ' : ''}popover`;

  return (
    <Box
      className={clsx(
        variants({ showDismiss, children: Boolean(children) }),
        className,
      )}
      {...rest}
    >
      {children && (
        <Text
          as="h2"
          className="text text-100 font-heading font-semibold leading-snug"
        >
          {children}
        </Text>
      )}
      {showDismiss && (
        <PopoverDismiss>
          <Clickable
            className="absolute top-0 right-0 p-2 z-1"
            aria-label={closeLabel}
            onClick={onDismissClick}
            extend={popoverDismissStyles}
          >
            <CrossIcon size={3} />
          </Clickable>
        </PopoverDismiss>
      )}
    </Box>
  );
};
